html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #187fba;
}

#root {
  display: flex;
  font-size: 15px;
}